import React, { BaseSyntheticEvent } from 'react';

export const getHandlerKayPress = (key: string, cb: (e: React.KeyboardEvent) => void) => {
  return (e: React.KeyboardEvent) => {
    if (e.key === key) {
      cb(e);
    }
  };
};

export const handlerStopAndPrevent = (e: BaseSyntheticEvent) => {
  e.stopPropagation();
  e.preventDefault();
};
