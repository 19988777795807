import {
  addDays,
  addMinutes,
  differenceInCalendarDays,
  differenceInYears,
  endOfDay,
  format,
  isValid,
  max,
  min,
  set,
  toDate as fnsToDate,
} from 'date-fns';
import { APP_FORMAT_DATE } from '../configs/const';

export type DateValue = Date | string | number | null | undefined;

export const dateFormat = (
  value: DateValue,
  _format: string = APP_FORMAT_DATE,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
) => {
  if (isDateLike(value)) {
    return format(convertToDate(value), _format, options);
  }
  return '--';
};

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}?((\.\d+)|$)/gi.test(String(value));
};

export const isDateLike = (value: any): value is string | number | Date => {
  if (!value) return false;
  if (typeof value === 'string') {
    return isValid(new Date(value));
  }
  return isValid(fnsToDate(value));
};

export const convertToDate = (value: DateValue) => {
  if (!value) {
    return new Date();
  }
  if (typeof value === 'string') {
    return new Date(value);
  }
  return fnsToDate(value);
};

export const maxDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return max(values);
};
export const minDate = (...args: DateValue[]) => {
  const values = args.filter(isDateLike).map(convertToDate);
  return min(values);
};

export const getAge = (dateOfBirth: DateValue) => {
  return isDateLike(dateOfBirth) ? differenceInYears(new Date(), convertToDate(dateOfBirth)) : 0;
};
export const withFullName = <T extends { firstName?: string | null; lastName?: string | null }>(
  data: T,
) => {
  return { ...data, fullName: [data.firstName, data.lastName].filter(Boolean).join(' ') };
};
export const isTeenager = (dateOfBirth: DateValue) => {
  return getAge(dateOfBirth) < 18;
};

export const isPastDate = (value: DateValue) => {
  if (!isDateLike(value)) return false;
  const date = convertToDate(value);
  let now = new Date();

  const yesterday = endOfDay(addDays(now, -1));

  return yesterday.getTime() > date.getTime();
};
export const isFutureDate = (value: DateValue) => {
  if (!isDateLike(value)) return false;
  const date = convertToDate(value);
  let now = new Date();

  return now.getTime() < date.getTime();
};
export const calcDaysLeft = (from: DateValue, to: DateValue) => {
  const start = convertToDate(from);
  const end = convertToDate(to);

  return differenceInCalendarDays(end, start);
};

const roundToNearestHoursFloor = (date: Date) => {
  return set(date, { minutes: 0, seconds: 0, milliseconds: 0 });
};
const roundToNearestHoursCeil = (date: Date) => {
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();
  const isFloor = [minutes, seconds, milliseconds].every((num) => num === 0);

  if (isFloor) return date;

  return set(date, { hours: date.getHours() + 1, minutes: 0, seconds: 0, milliseconds: 0 });
};
const roundToNearestHoursRound = (date: Date) => {
  const minutesMS = date.getMinutes() * 60 * 1000;
  const secondsMS = date.getSeconds() * 1000;
  const milliseconds = date.getMilliseconds();

  const sum = minutesMS + secondsMS + milliseconds;

  const halfMS = 30 * 60 * 1000;

  if (sum > halfMS) {
    return roundToNearestHoursCeil(date);
  }

  return roundToNearestHoursFloor(date);
};

interface RoundToNearestHoursOptions {
  roundingMethod: 'ceil' | 'floor' | 'round';
}
export const roundToNearestHours = (date: Date, options?: RoundToNearestHoursOptions) => {
  const { roundingMethod = 'round' } = options || {};

  const method = (function () {
    switch (roundingMethod) {
      case 'ceil':
        return roundToNearestHoursCeil;
      case 'floor':
        return roundToNearestHoursFloor;
      default:
        return roundToNearestHoursRound;
    }
  })();

  return method(date);
};

export const excelTemplateDate = (value: DateValue, _format = APP_FORMAT_DATE) =>
  isDateLike(value) ? dateFormat(value, _format) : '-';

export const createIntervalMinutes = (from: Date, to: Date, interval: number) => {
  const res: Date[] = [from];

  while (true) {
    const current = res[res.length - 1];
    const next = addMinutes(current, interval);

    if (next.getTime() >= to.getTime()) {
      res.push(to);
      break;
    }

    res.push(next);
  }

  return res;
};
