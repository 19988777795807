import React, { useCallback, useMemo } from 'react';
import { Box, Collapse, Grid, InputBase, Tooltip } from '@material-ui/core';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { useFieldProps, useFormDefaultValue } from 'hooks';
import {
  useSourceClinicalMeetingSubjects,
  useSourceClinicalMeetingTypes,
  useSourceDoctors,
  useSourceEmployees,
} from 'components/hooks';
import { apiUserPatientProfileSubscriptions } from 'services/user-patient-profile-subscriptions';
import { SubscriptionInfo } from '../subscription-info';
import { SelectPatients } from 'components/select-patients';
import { AppCheckbox } from 'components/app-checkbox';
import { MEETING_TYPE_KEY } from 'services/clinical-meeting-types';
import { Stack } from 'components/stack';
import { useTranslate } from 'hooks/use-translate';
import { EmployeeMeetingDateSelect } from 'components/employee-meeting-date-select';
import { SelectEmployee } from 'components/select-employee';
import {
  apiUserEmployeeProfiles,
  ServiceUserEmployeeProfile,
} from 'services/user-employee-profiles';
import { isAcrossTimeSlots, TimeSlot, validateTimeSlot } from 'utils/app-helpers';
import { PERMISSION_IDS } from 'services/user-employee-profile-permissions';
import { ListEmployee } from '../list-employee';
import { AppInput } from 'components/app-input';
import { ClinicalMeetingFormModel, ClinicalMeetingMeta } from '../../models';
import { DateValue } from 'utils/dates';
import { AppSelect } from 'components/app-select';
import { validateRule } from 'AurionCR/components/formV2';
import { rulesMeetingDateRange, calcMeetingTime, STRATEGY_TO_DOCTOR } from '../../helpers';
import { RegisterOptions } from 'react-hook-form';
import { useClientMeetingDefaultDuration } from '../../hooks';
import { ClinicalMeeting } from 'services/clinical-meetings';
import { GetPreferred } from '../../../employee-meeting-date-select/@type';

const factoryIsAcrossWithMeeting = (timeSlot: TimeSlot) => {
  return (meeting: Pick<ClinicalMeeting, 'meetingFromDateTime' | 'meetingToDateTime'>) => {
    return isAcrossTimeSlots(timeSlot, {
      fromTime: meeting.meetingFromDateTime,
      toTime: meeting.meetingToDateTime,
    });
  };
};

const useGetSubscriptionInfo =
  apiUserPatientProfileSubscriptions.useGetLatestPatientSubscriptionQuery;

type Rules = Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;

interface WithDietitianFieldsProps {
  rulesEmployee: Rules;
  rulesDates: Rules;
  disabled: boolean;
}
const WithDietitianFields: React.FC<WithDietitianFieldsProps> = ({
  rulesEmployee,
  rulesDates,
  disabled,
}) => {
  const { t } = useTranslate();
  const { control, errors, watch, setValue, trigger } = useFormContext<ClinicalMeetingFormModel>();
  const getFieldProps = useFieldProps({ errors });

  const date = watch('date');

  const sourceMeetingSubjects = useSourceClinicalMeetingSubjects();

  const userEmployeeProfileID = watch('userEmployeeProfileID');

  const clinicalMeetingSubjectID = watch('clinicalMeetingSubjectID');
  const clinicalMeetingSubject = sourceMeetingSubjects.map[clinicalMeetingSubjectID];

  const getDefaultDuration = useClientMeetingDefaultDuration(clinicalMeetingSubjectID);

  const meetingDateTimeDoctor = watch('meetingDateTimeDoctor');

  const sourceEmployees = useSourceEmployees();

  const optionsEmployees = useMemo(() => {
    // only dietitians
    return sourceEmployees.data.filter((employee) => {
      return employee.userEmployeeProfilePermissionID === PERMISSION_IDS.DIETITIAN;
    });
  }, [sourceEmployees.data]);

  const filterDietitiansDate = meetingDateTimeDoctor ? meetingDateTimeDoctor[0] : null;

  const { data: dietitians, isFetching } =
    apiUserEmployeeProfiles.useGetSourceEmployeeDietitiansDailyAvailabilityQuery(
      {
        date: filterDietitiansDate,
      },
      { skip: !filterDietitiansDate },
    );

  const selectedDietitianID = userEmployeeProfileID;
  const availableDietitians = useMemo(() => {
    if (!meetingDateTimeDoctor) {
      return [];
    }

    if (!dietitians) {
      return [];
    }

    const afterDelay = clinicalMeetingSubject?.nextMeetingGapDuration || 0;

    return dietitians
      .map(ServiceUserEmployeeProfile.makeDailyAvailabilitySlot)
      .map((item) => {
        const duration = getDefaultDuration(item.id);

        const meetings = item.clinicalMeetings;

        const before = calcMeetingTime({
          meetingTime: meetingDateTimeDoctor,
          strategy: STRATEGY_TO_DOCTOR.BEFORE,
          delay: 0,
          duration,
        });
        const after = calcMeetingTime({
          meetingTime: meetingDateTimeDoctor,
          strategy: STRATEGY_TO_DOCTOR.AFTER,
          delay: afterDelay,
          duration,
        });

        const { _availability } = item;

        const timeSlotBefore = { fromTime: before[0], toTime: before[1] };
        const isBefore = validateTimeSlot(timeSlotBefore, _availability);
        const isBeforeAcross = meetings.some(factoryIsAcrossWithMeeting(timeSlotBefore));

        const timeSlotAfter = { fromTime: after[0], toTime: after[1] };
        const isAfter = validateTimeSlot(timeSlotAfter, _availability);
        const isAfterAcross = meetings.some(factoryIsAcrossWithMeeting(timeSlotAfter));

        return {
          ...item,
          before: isBefore && !isBeforeAcross ? before : null,
          after: isAfter && !isAfterAcross ? after : null,
        };
      })
      .filter((item) => item.before || item.after);
  }, [meetingDateTimeDoctor, dietitians, clinicalMeetingSubject, getDefaultDuration]);

  const onSetDietitian = useCallback(
    (options: { itemID: string; range: [DateValue, DateValue] }) => {
      setValue('userEmployeeProfileID', options.itemID);
      setValue('meetingDateTimeUserEmployeeProfile', options.range);
    },
    [setValue],
  );

  const renderSendSms = useCallback(
    (renderProps: ControllerRenderProps<ClinicalMeetingFormModel>) => {
      return (
        <Tooltip title={t('send-sms')}>
          <Box mt={1.5}>
            <AppCheckbox {...getFieldProps(renderProps)} label={null} disabled={disabled} />
          </Box>
        </Tooltip>
      );
    },
    [getFieldProps, disabled, t],
  );

  const getPreferred = useCallback<GetPreferred>(
    (timeline) => {
      if (!clinicalMeetingSubject) return false;
      if (!clinicalMeetingSubject.isFirstMeeting) return false;

      return timeline.id.endsWith('40');
    },
    [clinicalMeetingSubject],
  );

  return (
    <>
      <Collapse in={Boolean(filterDietitiansDate)}>
        <div>
          <ListEmployee
            items={availableDietitians}
            selectedEmployeeID={selectedDietitianID}
            onSelect={onSetDietitian}
            isLoading={isFetching}
          />
        </div>
      </Collapse>
      <Stack spacing={2} width={'100%'}>
        <Box width={'100%'}>
          <Controller
            control={control}
            name={'userEmployeeProfileID'}
            rules={rulesEmployee}
            render={(renderProps) => {
              return (
                <SelectEmployee
                  {...getFieldProps(renderProps)}
                  options={optionsEmployees}
                  fullWidth={true}
                  disabled={disabled}
                  label={t('user-employee-profile-dietitian')}
                  onChange={(e) => {
                    setValue('meetingDateTimeUserEmployeeProfile', null);
                    renderProps.onChange(e);
                  }}
                />
              );
            }}
          />
        </Box>
        <div>
          <Controller
            control={control}
            name={'meetingDateTimeUserEmployeeProfile'}
            rules={rulesDates}
            render={(renderProps) => {
              return (
                <EmployeeMeetingDateSelect
                  {...getFieldProps(renderProps)}
                  disabled={disabled || !userEmployeeProfileID}
                  userEmployeeID={userEmployeeProfileID}
                  preferStartDate={date}
                  onChange={(value) => {
                    renderProps.onChange(value);
                    trigger();
                  }}
                  getPreferred={getPreferred}
                  defaultDuration={getDefaultDuration(userEmployeeProfileID)}
                />
              );
            }}
          />
        </div>
        <Controller control={control} name={'isSendSmsEmployee'} render={renderSendSms} />
      </Stack>
    </>
  );
};

interface Props {
  isLoading: boolean;
  meetingTypeKey?: MEETING_TYPE_KEY;
  times: { id: string }[];
  meta: ClinicalMeetingMeta;
}

export const ClinicalMeetingFormNew: React.FC<Props> = ({ isLoading, meetingTypeKey, meta }) => {
  const { t } = useTranslate();
  const { control, errors, watch, getValues, setValue, trigger } =
    useFormContext<ClinicalMeetingFormModel>();

  const getFieldProps = useFieldProps({ errors });

  const date = watch('date');

  const sourceDoctors = useSourceDoctors(true);

  const sourceMeetingTypes = useSourceClinicalMeetingTypes();
  const meetingTypesOptions = useMemo(() => {
    return meetingTypeKey
      ? sourceMeetingTypes.data.filter((item) => item.meetingTypeKey === meetingTypeKey)
      : sourceMeetingTypes.data;
  }, [sourceMeetingTypes.data, meetingTypeKey]);

  const userPatientProfileID = watch('userPatientProfileID');
  const doctorID = watch('doctorID');

  const { data: userPatientSubscription } = useGetSubscriptionInfo(userPatientProfileID, {
    skip: !userPatientProfileID,
  });

  const validateAtLeastOne = useCallback(
    () =>
      Object.values(getValues(['doctorID', 'userEmployeeProfileID'])).some(Boolean) &&
      Object.values(
        getValues(['meetingDateTimeDoctor', 'meetingDateTimeUserEmployeeProfile']),
      ).some(Boolean)
        ? true
        : 'rule-at-least-one',
    [getValues],
  );

  const rulesEmployees = useMemo(() => {
    return {
      validate: {
        validateAtLeastOne,
      },
    };
  }, [validateAtLeastOne]);

  const rulesMeeting = useMemo(() => {
    return {
      ...rulesMeetingDateRange,
      validate: {
        ...rulesMeetingDateRange.validate,
        validateAtLeastOne,
      },
    };
  }, [validateAtLeastOne]);

  useFormDefaultValue(sourceDoctors.data[0]?.id, {
    name: 'doctorID',
    setValue,
    watch,
    skip: !userPatientProfileID,
  });

  const sourceMeetingSubjects = useSourceClinicalMeetingSubjects();
  const sourceMeetingSubjectsFirst = sourceMeetingSubjects.data[0];
  useFormDefaultValue(sourceMeetingSubjectsFirst?.id, {
    watch,
    setValue,
    name: 'clinicalMeetingSubjectID',
    emptyValue: '',
  });

  const renderSendSms = useCallback(
    (renderProps: ControllerRenderProps<ClinicalMeetingFormModel>) => {
      return (
        <Tooltip title={t('send-sms')}>
          <Box mt={1.5}>
            <AppCheckbox {...getFieldProps(renderProps)} label={null} disabled={isLoading} />
          </Box>
        </Tooltip>
      );
    },
    [getFieldProps, isLoading, t],
  );

  const clinicalMeetingSubjectID = watch('clinicalMeetingSubjectID');
  const meetingSubject = sourceMeetingSubjects.map[clinicalMeetingSubjectID];

  const isShowDietitian = useMemo(() => {
    if (!meetingSubject) {
      return false;
    }
    if (meetingSubject.isFirstMeeting) {
      return true;
    }
    if (!userPatientSubscription) {
      return false;
    }
    return userPatientSubscription.visitDietitian;
  }, [userPatientSubscription, meetingSubject]);

  const getDefaultDuration = useClientMeetingDefaultDuration(clinicalMeetingSubjectID);

  const getPreferred = useCallback<GetPreferred>(
    (timeline) => {
      if (!meetingSubject) return false;
      if (!meetingSubject.isFirstMeeting) return false;

      return timeline.id.endsWith('00');
    },
    [meetingSubject],
  );

  return (
    <Grid container spacing={2}>
      <Controller name="date" control={control} as={<InputBase type="hidden" />} />
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'clinicalMeetingTypeID'}
          rules={validateRule('required')}
          render={(renderProps) => {
            return (
              <AppSelect
                {...getFieldProps(renderProps)}
                label={t('meeting-type')}
                disabled={isLoading}
                options={meetingTypesOptions}
                loading={sourceMeetingTypes.loading}
                disableClearable
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ bgcolor: '#F6F8F8', mt: 1, px: 1, mx: -1, borderRadius: 6, overflow: 'hidden' }}>
          <Controller
            control={control}
            name={'userPatientProfileID'}
            rules={validateRule('required')}
            render={(renderProps) => {
              return (
                <SelectPatients
                  {...getFieldProps(renderProps)}
                  disabled={isLoading || meta.disablePatient}
                  disableClearable
                />
              );
            }}
          />
        </Box>
      </Grid>
      {userPatientSubscription && (
        <Grid item xs={12}>
          <SubscriptionInfo
            sx={{ pt: 1.1, pb: 1.4 }}
            label={t('subscription')}
            title={userPatientSubscription.title || ''}
            start={userPatientSubscription.startDate}
            end={userPatientSubscription.endDate}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Collapse in={Boolean(userPatientProfileID)}>
          <div>
            <Stack spacing={2} width={'100%'}>
              <Box width={'100%'}>
                <Controller
                  control={control}
                  name={'doctorID'}
                  rules={rulesEmployees}
                  render={(renderProps) => {
                    return (
                      <AppSelect
                        {...getFieldProps(renderProps)}
                        fullWidth={true}
                        disabled={isLoading}
                        options={sourceDoctors.data}
                        loading={sourceDoctors.loading}
                        onChange={(value) => {
                          setValue('meetingDateTimeDoctor', null);
                          renderProps.onChange(value);
                        }}
                      />
                    );
                  }}
                />
              </Box>
              <div>
                <Controller
                  control={control}
                  name={'meetingDateTimeDoctor'}
                  rules={rulesMeeting}
                  render={(renderProps) => {
                    return (
                      <EmployeeMeetingDateSelect
                        {...getFieldProps(renderProps)}
                        disabled={isLoading || !doctorID}
                        userEmployeeID={doctorID}
                        preferStartDate={date}
                        onChange={(value) => {
                          renderProps.onChange(value);
                          trigger();
                        }}
                        getPreferred={getPreferred}
                        defaultDuration={getDefaultDuration(doctorID)}
                      />
                    );
                  }}
                />
              </div>
              <Controller control={control} name={'isSendSmsDoctor'} render={renderSendSms} />
            </Stack>
            {isShowDietitian && (
              <WithDietitianFields
                disabled={isLoading}
                rulesDates={rulesMeeting}
                rulesEmployee={rulesEmployees}
              />
            )}
          </div>
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={'organizerUserEmployeeProfileID'}
          rules={validateRule('required')}
          render={(renderProps) => (
            <SelectEmployee {...getFieldProps(renderProps)} disableClearable />
          )}
        />
        <Controller
          control={control}
          name={'remarks'}
          render={(renderProps) => (
            <AppInput {...getFieldProps(renderProps)} multiline minRows={2} />
          )}
        />
      </Grid>
    </Grid>
  );
};
