const API_ENDPOINT = 'Pharmacies';

export const API_PHARMACIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: Pick<Pharmacy, 'id'>) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: Pick<Pharmacy, 'id'>) => `${API_ENDPOINT}/Delete/${data.id}`,
};
export enum PharmacyApi {
  None = 1,
  Neopharm,
}
export interface IPharmacy {
  id: string;
  isActive: boolean;
  name: string;
  address: string;
  email1: string;
  email2: string;
  defaultMessageSubject: string;
  defaultMessageHtml: string;
  pharmacyApi: PharmacyApi;
}
export class Pharmacy implements IPharmacy {
  id!: string;
  isActive = true;
  name = '';
  address = '';
  email1 = '';
  email2 = '';
  defaultMessageSubject = '';
  defaultMessageHtml = '';
  pharmacyApi = PharmacyApi.None;
}
