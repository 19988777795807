import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { useTranslate } from 'hooks/use-translate';
import { MenuItem } from '@material-ui/core';
import { apiNotebooks } from 'services/notebook';
import { isMutationRejected } from 'utils/rtk-query';
import { useAppDispatch } from 'store';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { requestError } from 'AurionCR/components';

const useCloneMutation = apiNotebooks.useCloneNotebookMutation;

interface Props extends GridControlsDropDownTemplateProps<{ id: string }> {}
export const ControlClone: React.FC<Props> = ({ data, handleClose, onTask }) => {
  const { tp } = useTranslate();
  const dispatch = useAppDispatch();

  const [perform, result] = useCloneMutation();

  const handleClick = useCallback(async () => {
    const res = await perform(data.id);

    if (isMutationRejected(res)) {
      return dispatch(notifyRequestResult(requestError(res.error, res.error?.message), 'error'));
    }

    dispatch(notifyRequestResult(tp('success-cloned'), 'success'));
    handleClose();
    onTask([['getData']]);
  }, [perform, data.id, dispatch, tp, onTask, handleClose]);

  return (
    <MenuItem onClick={handleClick} disabled={result.isLoading}>
      {tp('clone')}
    </MenuItem>
  );
};
