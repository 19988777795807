import React, { useCallback, useState } from 'react';
import { apiUserPatientProfile } from 'services/user-patient-profile';
import { useOpen } from 'AurionCR/components/hooks';
import { DialogConfirm } from 'components/dialog-confirm';
import { composeFunctions } from 'utils';
import { useTranslate } from 'hooks/use-translate';
import { useSourcePharmacies } from '../../../hooks';
import { PharmacyApi } from 'services/pharmacies';

const useLazyFetch = apiUserPatientProfile.useLazyGetPatientForGeneralFormQuery;

type RenderProps = {
  isLoading: boolean;
  onActionWrap: () => void;
};

interface Props {
  userPatientProfileID: string;
  targetType: PharmacyApi;
  onAction: () => void;
  children: (renderProps: RenderProps) => React.ReactNode;
}
export const CheckPharmacyType: React.FC<Props> = ({
  userPatientProfileID,
  children,
  targetType,
  onAction,
}) => {
  const sourcePharmacies = useSourcePharmacies();
  const { tp } = useTranslate();
  const { isOpen, handleOpen, handleClose } = useOpen();
  const [preferType, setPreferType] = useState<PharmacyApi | null>(null);
  const [performGet, resultGet] = useLazyFetch();

  const onActionWrap = useCallback(async () => {
    const result = await performGet(userPatientProfileID);

    if (!result.data) return;

    const preferedPharmacyID = result.data.preferedPharmacyID;

    if (!preferedPharmacyID) return onAction();

    const pharmacy = sourcePharmacies.map[preferedPharmacyID];

    if (!pharmacy) return onAction();

    const preferType = pharmacy.pharmacyApi || null;
    setPreferType(preferType);

    if (preferType === targetType || preferType === null) return onAction();

    handleOpen();
  }, [performGet, userPatientProfileID, targetType, onAction, handleOpen, sourcePharmacies.map]);

  const isLoading = resultGet.isLoading || sourcePharmacies.loading;

  return (
    <>
      {children({ isLoading, onActionWrap })}
      {isOpen && (
        <DialogConfirm
          title={tp('confirm')}
          message={tp('confirm-send-not-prefer-pharmacy', {
            type: preferType ? PharmacyApi[preferType] : 'unknown',
          })}
          onConfirm={composeFunctions(onAction, handleClose)}
          onCancel={handleClose}
        />
      )}
    </>
  );
};
