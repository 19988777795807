import React, { useCallback } from 'react';
import { GridControlsDropDownTemplateProps } from 'models/grid';
import { useTranslate } from 'hooks/use-translate';
import { composeFunctions } from 'utils';
import { CustomHandler, GridModel } from '../models';
import { useGridMedicalPrescriptions } from '../hooks';
import { BaseMenuItem } from '../base-menu-item';

interface Props extends GridControlsDropDownTemplateProps<GridModel> {}
export const ControlSendCopyToPatient: React.FC<Props> = ({ data, handleClose }) => {
  const { t } = useTranslate();
  const disabled = !data.prescriptionWatermarkFilePath;
  const { onTasks } = useGridMedicalPrescriptions();
  const onClick = useCallback(() => {
    onTasks([['onHandle', { type: CustomHandler.SEND_COPY_TO_PATIENT, data }]]);
  }, [onTasks, data]);

  return (
    <BaseMenuItem
      prescriptionID={String(data.id)}
      onClick={composeFunctions(onClick, handleClose)}
      disabled={disabled}
      ignoreDisabled={true}
    >
      {t('send-prescription-copy-to-patient')}
    </BaseMenuItem>
  );
};
