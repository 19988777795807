import React, { memo, useEffect, useMemo, useState } from 'react';
import { IDataGridFilter, IDataGridMadel } from '../../model';
import FilterString from './filter-string';
import FilterSelect from './filter-select';
import FilterDate from './filter-date';
import FilterDateMultiple from './filter-date-multiple';
import FilterSwitcher from './filter-switcher';
import { Box, BoxProps, Button, Popover } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useI18n } from 'AurionCR/components';
import clsx from 'clsx';

const FiltersMobileWrapper = memo(({ children }: { children: React.ReactNode }) => {
  const { t } = useI18n();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // render
  return (
    <div className="toolbar-filters__mobile">
      <Button
        variant="contained"
        color="secondary"
        className="toolbar-filters__mobile-btn-open"
        startIcon={<FilterListIcon />}
        endIcon={Boolean(anchorEl) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={handleClick}
      >
        <div className="title">{t('grid-filters')}</div>
      </Button>
      {Boolean(anchorEl) && (
        <Popover
          open={true}
          anchorEl={anchorEl}
          onClose={handleClose}
          className="toolbar-filters__mobile-filters"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {children}
        </Popover>
      )}
    </div>
  );
});

const FiltersList = memo(
  ({
    filtersList,
    filters,
    onTasks,
  }: {
    filtersList: IDataGridFilter[];
    filters: any;
    onTasks: any;
  }) => {
    return (
      <>
        {filtersList.map((filter) => {
          switch (filter.options?.type) {
            case 'string':
              return (
                <FilterString
                  key={filter.field}
                  filter={filter}
                  value={filters[filter.field]}
                  onTasks={onTasks}
                />
              );
            case 'select':
              return (
                <FilterSelect
                  key={filter.field}
                  filter={filter}
                  value={filters[filter.field]}
                  onTasks={onTasks}
                />
              );
            case 'date-multiple':
              return (
                <FilterDateMultiple
                  key={filter.field}
                  filter={filter}
                  value={filters[filter.field]}
                  onTasks={onTasks}
                />
              );
            case 'date':
              return (
                <FilterDate
                  key={filter.field}
                  filter={filter}
                  value={filters[filter.field]}
                  onTasks={onTasks}
                />
              );
            case 'switcher':
              return (
                <FilterSwitcher
                  key={filter.field}
                  filter={filter}
                  value={filters[filter.field]}
                  onTasks={onTasks}
                />
              );
            case 'component':
              return (
                filter.options.template && (
                  <filter.options.template
                    key={filter.field}
                    filter={filter}
                    value={filters[filter.field]}
                    onTasks={onTasks}
                  />
                )
              );
            default:
              return null;
          }
        })}
      </>
    );
  },
);

interface IFilterProps extends BoxProps {
  disableMobiles: boolean;
  model: IDataGridMadel;
  filters: any;
  onTasks: any;
}

const Filters = memo<IFilterProps>(({ model, disableMobiles, filters, onTasks, ...rest }) => {
  // states
  const [isMobile, setIsMobile] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isToolbar = useMemo(() => model?.toolbar && model?.toolbar?.visible, [model?.toolbar]);
  const Filters = useMemo(
    () =>
      isToolbar && model?.toolbar?.filters && model?.toolbar.filters.length > 0
        ? model?.toolbar.filters
        : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isToolbar],
  );

  // resize
  useEffect(() => {
    const onResize = () => setIsMobile(window.innerWidth < 768);
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setIsMobile]);

  const FiltersElement = Filters ? (
    <FiltersList filtersList={Filters} filters={filters} onTasks={onTasks} />
  ) : null;

  const renderInMobileWrap = !disableMobiles && isMobile;
  // render
  if (!isToolbar || !Filters) {
    return null;
  }

  return (
    <Box {...rest} className={clsx('toolbar-filters', rest.className)}>
      {renderInMobileWrap ? (
        <FiltersMobileWrapper>{FiltersElement}</FiltersMobileWrapper>
      ) : (
        FiltersElement
      )}
    </Box>
  );
});

export default Filters;
