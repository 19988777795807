import { DynamicService } from 'utils/service';
import { API_FORM_DOCUMENT_CATEGORIES, iFormDocumentCategory } from './models';
import { apiRtk } from 'utils/rtk-query';

export * from './models';

class Service extends DynamicService<iFormDocumentCategory> {}

export const ServiceFormDocumentCategory = new Service({
  mainField: 'id',
  getAll: API_FORM_DOCUMENT_CATEGORIES.GET_ALL_DYNAMIC,
  post: API_FORM_DOCUMENT_CATEGORIES.POST,
  patch: API_FORM_DOCUMENT_CATEGORIES.PATCH,
  delete: API_FORM_DOCUMENT_CATEGORIES.DELETE,
});

export const apiFormDocumentCategories = apiRtk.injectEndpoints({
  endpoints: (builder) => ({
    getFormDocumentCategoriesTabSigned: builder.query({
      queryFn: async (arg: void) => {
        const result = await ServiceFormDocumentCategory.getAllDynamic<
          Pick<
            iFormDocumentCategory,
            | 'id'
            | 'title'
            | 'icon'
            | 'color'
            | 'useForContract'
            | 'useForDrug'
            | 'formDocumentCategoryType'
          >
        >({
          select: [
            'id',
            'title',
            'icon',
            'color',
            'useForContract',
            'useForDrug',
            'formDocumentCategoryType',
          ].join(','),
        });
        return { data: result.data.value };
      },
    }),
  }),
});
