import React, { memo, useCallback, useEffect, useMemo } from 'react';
import GridConfig from './grid-config';
import DataGrid from 'components/data-grid';
import { useFormGrid } from 'AurionCR/components/formV2';
import { EditorProxy } from './editor';
import { GridTasks } from 'models/grid';
import { patientEmitter } from '../../emitter';
import { useOpenValue } from 'hooks';
import {
  MedicalPrescriptionDialogEmail,
  MedicalPrescriptionDialogEmailToPatient,
  MedicalPrescriptionDialogSendCopyToPatient,
} from 'components/medical-prescription';
import { ButtonGenerateGimel29 } from './button-generate-gimel29';
import { Box } from '@material-ui/core';
import { CustomHandler, CustomHandlers, GridModel } from './models';
import { IDataGridRowProps } from '../../../data-grid/model';
import clsx from 'clsx';
import style from './index.module.scss';
import { useCurrentUser } from '../../../hooks';

interface Props {
  userPatientProfileID: string;
}
export const GridMedicationsPrescriptions = memo<Props>(({ userPatientProfileID }) => {
  const onRefreshDocuments = useCallback(() => {
    patientEmitter.emit('refresh-documents', { patientID: userPatientProfileID });
  }, [userPatientProfileID]);

  const { $isAdmin } = useCurrentUser();

  const gridConfig = useMemo(() => {
    return GridConfig({
      userPatientProfileID,
      isAdmin: $isAdmin,
    });
  }, [userPatientProfileID, $isAdmin]);

  const { grid, onGridHandle, editItemID, setEditItemID } = useFormGrid('prescription');

  const onTasks = useCallback(
    (tasks: GridTasks<any>[]) => {
      if (grid.current?.onTasks) grid.current.onTasks(tasks);
    },
    [grid],
  );

  const onRefresh = useCallback(() => {
    onTasks([['getData']]);
  }, [onTasks]);

  useEffect(() => {
    const refresh = (payload: { patientID: string }) => {
      if (payload.patientID !== userPatientProfileID) return;
      grid.current?.onTasks([['getData']]);
    };

    patientEmitter.on('refresh-all', refresh);
    return () => {
      patientEmitter.off('refresh-all', refresh);
    };
  }, [userPatientProfileID, grid]);

  const stateSendToPatient = useOpenValue<string>();
  const stateSendCopyToPatient = useOpenValue<string>();
  const stateSendToPharmacy = useOpenValue<string>();

  const onCustomHandle = useCallback(
    (action: CustomHandlers) => {
      if (action.type === CustomHandler.SEND_TO_PATIENT) {
        return stateSendToPatient.onOpen(action.data.id);
      }
      if (action.type === CustomHandler.SEND_COPY_TO_PATIENT) {
        return stateSendCopyToPatient.onOpen(action.data.id);
      }
      if (action.type === CustomHandler.SEND_TO_PHARMACY) {
        return stateSendToPharmacy.onOpen(action.data.id);
      }

      onGridHandle(action);
    },
    [stateSendToPatient, stateSendCopyToPatient, stateSendToPharmacy, onGridHandle],
  );

  const rowProps = useCallback<IDataGridRowProps<GridModel>>(({ row }) => {
    return { className: clsx({ [style.row]: true, [style.rowHold]: row.isOnHold }) };
  }, []);

  return (
    <>
      <DataGrid
        ref={grid}
        config={gridConfig}
        onHandle={onCustomHandle}
        rowProps={rowProps}
        renderControlsSlot={() => (
          <Box mr={1}>
            <ButtonGenerateGimel29 patientID={userPatientProfileID} onDone={onRefreshDocuments} />
          </Box>
        )}
      />
      {editItemID && (
        <EditorProxy
          onClose={() => setEditItemID(false)}
          userPatientProfilePrescriptionID={editItemID}
          userPatientProfileID={userPatientProfileID}
          onDocumentsCreated={onRefreshDocuments}
          onRefresh={onRefresh}
        />
      )}
      {stateSendToPharmacy.openValue && (
        <MedicalPrescriptionDialogEmail
          userPatientProfileID={userPatientProfileID}
          userPatientProfilePrescriptionID={stateSendToPharmacy.openValue}
          onClose={stateSendToPharmacy.onClose}
          onDone={onRefresh}
        />
      )}
      {stateSendToPatient.openValue && (
        <MedicalPrescriptionDialogEmailToPatient
          userPatientProfilePrescriptionID={stateSendToPatient.openValue}
          onClose={stateSendToPatient.onClose}
          onDone={onRefresh}
        />
      )}
      {stateSendCopyToPatient.openValue && (
        <MedicalPrescriptionDialogSendCopyToPatient
          userPatientProfilePrescriptionID={stateSendCopyToPatient.openValue}
          onClose={stateSendCopyToPatient.onClose}
          onDone={onRefresh}
        />
      )}
    </>
  );
});
