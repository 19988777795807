import React from 'react';
import {
  CircularProgress,
  IconButton,
  MenuItem,
  MenuItemProps,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTranslate } from 'hooks/use-translate';
import { usePrescriptionCanComplete } from 'components/dialog-prescription-completeness';
import { WizardValidity } from 'components/dialog-prescription-completeness/components';
import { TemplateBooleanIcon } from 'components/templates/template-boolean-icon';
import { Stack } from 'components/stack';
import { withStyles } from '@material-ui/core/styles';

const MenuItemWithTooltip = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
})(MenuItem);

type Props<D extends React.ElementType = 'li', P = {}> = MenuItemProps<D, P> & {
  prescriptionID: string;
  ignoreDisabled?: boolean;
};
export const BaseMenuItem = <D extends React.ElementType = 'li', P = {}>({
  children,
  prescriptionID,
  ignoreDisabled,
  ...rest
}: Props<D, P>) => {
  const { tp } = useTranslate();

  const { isValid, canComplete, items, isLoading } = usePrescriptionCanComplete(prescriptionID);
  const disabled = ignoreDisabled ? false : rest.disabled || isLoading || !canComplete;

  return (
    <MenuItemWithTooltip
      {...rest}
      onClick={disabled ? undefined : rest.onClick}
      disabled={disabled}
    >
      <Stack alignItems={'center'} justifyContent={'space-between'} spacing={1} width={'100%'}>
        <Typography>{children}</Typography>
        <Tooltip
          title={
            <WizardValidity
              title={tp('prescription-validity')}
              isValid={isValid}
              isLoading={isLoading}
              options={items}
            />
          }
        >
          <IconButton size={'small'}>
            {isLoading ? <CircularProgress size={12} /> : <TemplateBooleanIcon value={isValid} />}
          </IconButton>
        </Tooltip>
      </Stack>
    </MenuItemWithTooltip>
  );
};
